<template lang="pug">
	section.banner(:style="`background: center / cover no-repeat ${isMobile ? backgroundMobile : background}`")
		.landing__container.banner__container
			h2.banner__title(:class="{'text-center': textAlign == 'center', [classTitle]: true}")
				span(:class="{'text-white': colors[0] == 'white', 'color-grey': colors[0] == 'grey'}" v-html="texts[0]")
				span(:class="{'text-white': colors[1] == 'white'}" v-html="texts[1]")

</template>

<script>
export default {
    name: 'Banner',
    props: ['background', 'backgroundMobile', 'texts', 'colors', 'textAlign', 'classTitle'],
};
</script>

<style lang="scss">
.banner {
    padding: 102px 0;

    @media (max-width: 991.98px) {
        padding: 84.5px 0;
    }

    @media (max-width: 767.98px) {
        padding: 67px 0;
    }

    @media (max-width: 575.98px) {
        padding: 49.5px 0;
    }

    &__container {
        padding-top: 0 !important;
    }

    &__title {
        position: relative;
        z-index: 2;
        max-width: 1180px;
        margin: 0 auto !important;

        @media (max-width: 767.98px) {
            text-align: center;
        }

        &.is-entry {
            line-height: 65px !important;

            @media (max-width: 991.98px) {
                line-height: 54px !important;
            }

            @media (max-width: 767.98px) {
                line-height: 43px !important;
            }

            @media (max-width: 575.98px) {
                line-height: 32px !important;
            }
        }

        &.is-skills {
            @media (max-width: 991.98px) {
                line-height: 44px !important;
            }

            @media (max-width: 767.98px) {
                line-height: 38px !important;
            }

            @media (max-width: 575.98px) {
                line-height: 32px !important;
            }
        }
    }
}
</style>
