<template lang="pug">
	.landing
		div
			div
				Header(@click="goToLogin")
				.landing__neuro
					.pt-0.landing__container
						Preview(
							:type="'neuro'"
							:title="$t('landingPage.preview.neurobot.title')",
							:description="$t('landingPage.preview.neurobot.description')",
							:button="$t('landingPage.preview.neurobot.button')"
							@click="goToLogin"
						)
				.landing__container
					MakeMoney(@click="goToLogin")
				Banner(
					background="url('/assets/img/landingPage/Neuro/banner/background-1.webp')"
					backgroundMobile="url('/assets/img/landingPage/Neuro/banner/background-1-mobile.webp')"
					:texts="[$t(`landingPage.banner.items[0].title[0]`), $t(`landingPage.banner.items[0].title[1]`)]"
					:colors="['white', 'white']"
					:textAlign="'center'"
					:classTitle="'is-entry'"
				)
				Tasks(@click="goToLogin")
				.landing__container
					Users
					Setup(@click="goToLogin")
				Banner(
					background="url('/assets/img/landingPage/Neuro/banner/background-2.webp')"
					backgroundMobile="url('/assets/img/landingPage/Neuro/banner/background-2-mobile.webp')"
					:texts="[$t(`landingPage.banner.items[1].title[0]`), $t(`landingPage.banner.items[1].title[1]`)]"
					:colors="['grey', 'default']"
					:textAlign="'left'"
					:classTitle="'is-skills'"
				)
				.overflow-hidden.landing__container
					Brand(@click="goToLogin")
				.landing__container
					Sell
				World(@click="goToLogin")
				Footer(v-if="!islocaleRuSystemAndIp")
				FooterRuDomain(v-if="islocaleRuSystemAndIp")

			ModalCookie
</template>

<script>
import { mapActions } from 'vuex';

import Header from '@/components/landingPage/Header';
import Preview from '@/components/landingPage/Preview';
import Footer from '@/components/landingPage/Footer';
import FooterRuDomain from '@/components/landingPage/FooterRuDomain';
import ModalCookie from '@/components/Modals/Cookie';

/*Нейроботы*/
import MakeMoney from '@/components/landingPage/Neuro/MakeMoney';
import Banner from '@/components/landingPage/Neuro/Banner';
import Tasks from '@/components/landingPage/Neuro/Tasks';
import Users from '@/components/landingPage/Neuro/Users';
import Setup from '@/components/landingPage/Neuro/Setup';
import Brand from '@/components/landingPage/Neuro/Brand';
import Sell from '@/components/landingPage/Neuro/Sell';
import World from '@/components/landingPage/Neuro/World';

export default {
    name: 'landingNeurobot',
    metaInfo() {
        return {
            titleTemplate: this.islocaleRuSystemAndIp
                ? 'Paywall - монетизация контента, сообществ и нейросетей в Телеграме.'
                : 'Paywall - Content monetization service',
        };
    },
    components: {
        Header,
        Preview,
        Footer,
        FooterRuDomain,
        ModalCookie,

        MakeMoney,
        Banner,
        Tasks,
        Users,
        Setup,
        Brand,
        Sell,
        World,
    },
    created() {
        this.viewGeoIpRussia();
    },
    mounted() {
        window.addEventListener('beforeunload', this.handleBeforeUnload);

        if (this.$route.hash) {
            setTimeout(() => this.scrollTo(this.$route.hash), 100);
        }

        this.rememberPageView();
    },
    beforeMount() {
        this.redirectToLoginIfMerchantPath();
    },
    beforeDestroy() {
        window.removeEventListener('beforeunload', this.handleBeforeUnload);
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            next(vm => {
                if (from.name != 'landingNeurobot') {
                    if (to.params.block) {
                        let distanceBlock = 0;

                        if (!to.params.distance) {
                            distanceBlock = -30;
                        } else {
                            distanceBlock = to.params.distance;
                        }

                        vm.$nextTick(() => {
                            const targetBlock = document.querySelector(to.params.block);

                            setTimeout(() => {
                                const top = window.scrollY + targetBlock.getBoundingClientRect().y + distanceBlock;

                                window.scrollTo(0, top);
                            }, 1000);
                        });
                    }
                }
            });
        });
    },
    methods: {
        ...mapActions({
            getGeoIpRussia: 'auth/geoIpRussia',
        }),
        redirectToLoginIfMerchantPath() {
            if (window.location.href.includes('merchant')) {
                if (this.me && this.me.id) return this.$router.replace({ name: 'projects' });

                return this.goToLogin();
            }
        },
        goToLogin(e) {
            if (e) {
                e.preventDefault();
                e.stopPropagation();
            }
            window.location.href = this.loginUrl;
        },
        handleBeforeUnload() {
            window.scrollTo({ top: 0, behavior: 'instant' });
        },
    },
};
</script>

<style lang="scss">
@import '~@/assets/css/sass/_landing.style.scss';
</style>
